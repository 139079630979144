import { GqlError, isExpectedGqlError } from "@insightfulscience/shared-utils/gqlClient";

export const isUnauthorized = isExpectedGqlError(401);
export const isNotAuthorized = isUnauthorized;

class NotAuthorizedError extends GqlError {
	static create() {
		return new NotAuthorizedError();
	}

	static reject() {
		return Promise.reject(NotAuthorizedError.create());
	}

	constructor() {
		super({ code: 401, message: "Not Authorized", path: [] });
	}
}

export default NotAuthorizedError;
