/* globals WEBPACK_GRAPHQL_URI  */
export const IS_SERVER = typeof window === "undefined";
export const IS_BROWSER = !IS_SERVER;

export const publicConfig = {
	// Build Time Configuration it will be boundeled to client and server
	RECURLY_PUBLIC_KEY: process.env.RECURLY_PUBLIC_KEY,
	GRAPHQL_URI: WEBPACK_GRAPHQL_URI,
	GRAPHQL_HEALTH_URL: process.env.GRAPHQL_HEALTH_URL,
	SB_CDN_BASE_URL: process.env.SB_CDN_BASE_URL,
	SB_CDN_HOST: process.env.SB_CDN_HOST,
	AWS_S3_PUBLIC_HOST: process.env.AWS_S3_PUBLIC_HOST,
	HTTP_PROXY: process.env.HTTP_PROXY,
	HTTPS_PROXY: process.env.HTTPS_PROXY,
	BUILD_NUMBER: process.env.BUILD_NUMBER,
	STATIC_SITE_DOMAIN: process.env.STATIC_SITE_DOMAIN,
	MAIN_SITE_DOMAIN: process.env.MAIN_SITE_DOMAIN,
	CI: process.env.CI,
	DEBUG_MODE: process.env.DEBUG_MODE,
	NODE_ENV: process.env.NODE_ENV,
	ENV: process.env.ENV,
	RELEASE: process.env.RELEASE,
	COOKIE_NAME: process.env.COOKIE_NAME,
	COOKIE_DOMAIN: process.env.COOKIE_DOMAIN ?? "graphpad.com",
	SENTRY_DSN: process.env.SENTRY_DSN,
	SENTRY_TRACING_RATE: process.env.SENTRY_TRACING_RATE,
	VWO_ID: process.env.VWO_ID,
	GTM_ENV_NAME: process.env.GTM_ENV_NAME,
	CTF_ENV: process.env.CTF_ENV,
	CTF_SPACE: process.env.CTF_SPACE,
	CTF_TOKEN: process.env.CTF_TOKEN,
	VERSION_NODE: process.env.VERSION_NODE,
	VERSION_NPM: process.env.VERSION_NPM,
	HUBSPOT_PORTAL_ID: process.env.HUBSPOT_PORTAL_ID,
	HUBSPOT_FREE_COURSE_LICENSE_FORM_ID: process.env.HUBSPOT_FREE_COURSE_LICENSE_FORM_ID,
	HUBSPOT_CONTACT_FORM_ID: process.env.HUBSPOT_CONTACT_FORM_ID,
	HUBSPOT_CLOUD_FORM_ID: process.env.HUBSPOT_CLOUD_FORM_ID,
	HUBSPOT_CONNECTED_LAB_FORM_ID: process.env.HUBSPOT_CONNECTED_LAB_FORM_ID,
};
