import { createGqlClient } from "@insightfulscience/shared-utils/gqlClient";
import {
	batch,
	context,
	recovery,
	tap,
} from "@insightfulscience/shared-utils/gqlClient/middlewares";
import signOutOn401 from "./signOutOn401";

const gqlClient = ({
	storageService: { token, refreshToken, clearAll, apiMock },
	recoveryService: { shouldBeRecovered, recover },
	publicConfig: { GRAPHQL_URI },
	gqlEventsListener,
	globalContext,
}) =>
	createGqlClient(
		{ uri: GRAPHQL_URI },
		tap(gqlEventsListener),
		signOutOn401({ refreshToken, clearAll }),
		recovery(shouldBeRecovered, recover()),
		batch(),
		context(() => ({
			headers: {
				Authorization: `Bearer ${token.read()}`,
				"x-mock": apiMock.read() ?? undefined,
				"x-request-id":
					globalContext?.req?.requestId ??
					Math.random()
						.toFixed(20)
						.slice(2),
			},
		})),
	);

export default gqlClient;
