import cookie from "js-cookie";

const clientCookie = (cookieProvider = cookie, { COOKIE_DOMAIN } = {}) => ({
	read: cookieName => cookieProvider.get()[cookieName],
	write: (cookieName, value, options) => {
		cookieProvider.set(cookieName, value, { ...options, domain: COOKIE_DOMAIN });
	},
	remove: cookieName => {
		cookieProvider.remove(cookieName, { domain: COOKIE_DOMAIN });
	},
});

export default clientCookie;
