const ssoService = () => ({
	performSso: () =>
		fetch("/njs-api/auth/status", { credentials: "include", mode: "no-cors" })
			.then(res => (res.ok ? res.json() : { isAuthorized: false }))
			.then(
				result => result.isAuthorized,
				() => false,
			),
});

export default ssoService;
