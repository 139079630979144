// Unable to call GP API client side
// forcing page reload

import CLIENT_SIDE_AUTH_STATE from "../../CLIENT_SIDE_AUTH_STATE";

const gpClientService = {
	getRequestAccessToken: () => {
		window.location.reload();
		return new Promise(() => {
			// it never resolves to allow page to be reloaded without breaking
			// js-flow on the current page
		});
	},
	getGpAuthState: () => CLIENT_SIDE_AUTH_STATE,
};

export default gpClientService;
