import { idX } from "@insightfulscience/shared-utils/fn";
import { stringify, parse } from "@insightfulscience/shared-utils/parsers/json";
import diContainer from "true-di";

const createItemStorage = (
	storage,
	keyName,
	defOptions,
	serialize = stringify,
	deserialize = parse,
) => ({
	write: (value, options) =>
		storage.write(keyName, serialize(value), { ...defOptions, ...options }),
	read: () => deserialize(storage.read(keyName)),
	clear: () => storage.remove(keyName),
});

const StorageService = ({ storage }) =>
	diContainer({
		priceItem: () => createItemStorage(storage, "priceItem"),
		billingInfo: () => createItemStorage(storage, "billingInfo"),
		token: () => createItemStorage(storage, "token", { expires: 365 }, idX, idX),
		refreshToken: () => createItemStorage(storage, "rtoken", { expires: 365 }, idX, idX),

		setTokens: self => ({ token, refreshToken }) => {
			self.token.write(token);
			self.refreshToken.write(refreshToken);
		},

		clearAll: self => () => {
			self.priceItem.clear();
			self.billingInfo.clear();
			self.token.clear();
			self.refreshToken.clear();
		},
		apiMock: () => createItemStorage(storage, "x-api-mock"),
	});

export default StorageService;
