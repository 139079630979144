import NextPageWithServices from "@insightfulscience/smart-react/hocs/NextPageWithServices";
import diContainer from "true-di";
import { publicConfig } from "../config";
import storage from "./storage";
import gqlClient from "./gqlClient";
import recoveryService from "./recoveryService";
import storageService from "./storageService";
import routingService from "./routingService";
import authService from "./authService";

export const serviceCore = {
	publicConfig: () => publicConfig,
	storage,
	storageService,
	recoveryService,
	gqlClient,
	routingService,
	authService,
};

export const createContainer = (globalContext, extraServices) =>
	diContainer({
		globalContext: () => globalContext,
		...serviceCore,
		...extraServices,
	});

export default NextPageWithServices(serviceCore);
