const Cookies = ({ globalContext, publicConfig }) =>
	typeof window === "undefined"
		? require("./server-side").default(
				globalContext,
				require("../../async-local-storage/cookies-cache").getCookiesCache(),
				publicConfig,
		  )
		: require("./client-side").default(undefined, publicConfig);

export default Cookies;
