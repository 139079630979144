export const single = fn => {
	let promise = null;

	const reset = () => {
		promise = null;
	};

	return (...args) => {
		promise = promise ?? fn(...args).finally(reset);
		return promise;
	};
};
