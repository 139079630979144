import gql from "@insightfulscience/shared-utils/gqlClient/gql";
import { Operation } from "@insightfulscience/shared-utils/gqlClient/operation";
import { isTokenValid } from "@insightfulscience/shared-utils/jwt/jwtPayload";
import { isUnauthorized } from "../../errors/NotAuthorizedError";
import { onError } from "../../utils/onError";

export const SIGN_OUT = gql`
	mutation signOut($rToken: String!) {
		signOut(refreshToken: $rToken)
	}
`;

const signOutOn401 = ({ clearAll, refreshToken }) =>
	onError((err, { last: gqlClient }) => {
		if (!isUnauthorized(err)) return Promise.reject(err);

		const rToken = refreshToken.read();
		clearAll();

		const promise = isTokenValid(refreshToken)
			? gqlClient(Operation(SIGN_OUT, { rToken }), {})
			: Promise.resolve();

		return promise.then(() => Promise.reject(err));
	});

export default signOutOn401;
