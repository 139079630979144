import gql from "@insightfulscience/shared-utils/gqlClient/gql";

export const REFRESH_ACCESS = gql`
	mutation refreshAccess($refreshToken: String!) {
		refreshAccess(refreshToken: $refreshToken) {
			token
			refreshToken
		}
	}
`;
