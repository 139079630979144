import { Operation } from "@insightfulscience/shared-utils/gqlClient/operation";
import { isExpectedGqlError } from "@insightfulscience/shared-utils/gqlClient/GqlError";
import { isTokenValid } from "@insightfulscience/shared-utils/jwt/jwtPayload";
import { REFRESH_ACCESS } from "./REFRSH_ACCESS";
import { single } from "../../utils/single.js";

const isUnauthorized = isExpectedGqlError(401);

const RecoveryService = ({ storageService: { refreshToken, setTokens } }) => ({
	shouldBeRecovered: err => isUnauthorized(err) && isTokenValid(refreshToken.read()),
	recover: () =>
		single((context, operation, gqlClient) =>
			gqlClient
				.last(Operation(REFRESH_ACCESS, { refreshToken: refreshToken.read() }), context)
				.then(({ refreshAccess }) => setTokens(refreshAccess)),
		),
});

export default RecoveryService;
