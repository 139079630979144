import React from "react";
import NextApp from "next/app";
import "nprogress/nprogress.css";
import { LocaleProvider } from "@insightfulscience/smart-react/i18";
import "../styles/override-nprogress.css";
import AssetsBaseUrlProvider from "@src/AssetsBaseUrlProvider";
import "@src/next-router-events";
import { initSentry } from "@src/sentry";
import { createContainer } from "@src/services";
import { ssoServices } from "@src/services/ssoService";
import { AuthStatusContextProvider } from "@src/hooks/useIsAuthorized";
import resources from "../locales";

initSentry();

// eslint-disable-next-line react/prop-types
const App = ({ Component, pageProps, err, authStatus }) => (
	<LocaleProvider resources={resources}>
		<AuthStatusContextProvider value={authStatus}>
			<AssetsBaseUrlProvider>
				<Component {...pageProps} err={err} />
			</AssetsBaseUrlProvider>
		</AuthStatusContextProvider>
	</LocaleProvider>
);

const getAppProps = appContext => isAuthorized =>
	NextApp.getInitialProps(appContext).then(appProps => ({
		authStatus: { isAuthorized },
		...appProps,
	}));

App.getInitialProps = appContext =>
	createContainer(appContext.ctx, ssoServices)
		.ssoService.performSso()
		.then(getAppProps(appContext));

export default App;
