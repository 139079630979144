import diContainer from "true-di";
import storageService from "./storageService";
import storage from "./storage";

export const PersistentStorage = globalContext =>
	diContainer({
		globalContext: () => globalContext,
		storage,
		storageService,
	}).storageService;
