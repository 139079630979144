import React from "react";
import AssetsProvider from "@insightfulscience/atomic-react/utils/AssetsProvider";
import { node } from "prop-types";

const baseAssetsUrl = `${process.env.SB_CDN_HOST}${process.env.SB_CDN_BASE_URL}/assets`;

const AssetsBaseUrlProvider = ({ children }) => (
	<AssetsProvider assetPrefix={baseAssetsUrl}>{children}</AssetsProvider>
);

AssetsBaseUrlProvider.propTypes = {
	children: node,
};

export default AssetsBaseUrlProvider;
