import { jwtPayload } from "@insightfulscience/shared-utils/jwt/jwtPayload";

export const isTokenValid = token => Boolean(token) && jwtPayload(token) != null;

const authService = ({ storageService: { token, refreshToken } }) => {
	const isAuthorized = () => isTokenValid(token.read()) && isTokenValid(refreshToken.read());

	return { isAuthorized };
};

export default authService;
