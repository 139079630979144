import gpApiConfig from "../gpApiConfig";
import gpApiClient from "../gpApiClient";

export const ssoService =
	typeof window !== "undefined"
		? require("./client-side").default // client-side
		: require("./server-side").default;

export const ssoServices = {
	gpApiConfig,
	gpApiClient,
	ssoService,
};
